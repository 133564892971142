jQuery(function($) {
    "use strict";



    //Click event to scroll to top
    $(document).on('click', '.to-top', function() {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    // enable bootstrap tooltips
    $('[data-toggle="tooltip"]').tooltip()

    // call function of fullscreen menu
    fullScreenMenu()

    /**
     * change language
     * 
     */
    $('.change-language').on('click', function(event) {
        event.preventDefault();
        console.log('here')
        var data = $(this).data()
        $.ajax({
            url: "/language",
            method: 'get',
            data : data,
            success: function(response) {
                if (response.redirect) {
                    window.location = response.new_route;
                }
            }
        })
    })

    /**
     * change currency
     *  
     */
    $('.change-currency').on('click', function (event) {
        event.preventDefault()
        var data = $(this).data()
        $.ajax({
            url: "/currency",
            method: 'get',
            data : data,
            success: function(response) {
                localStorage.clear();
                location.reload()
            }
        })
    })

    $('.nav-item-locations:not(.mobile), .nav-item-vehicles:not(.mobile)').on('click', function(ev) {
        ev.preventDefault()
        location.href = $(this).prop('href')
    })

    /**
     * load info footer
     * 
     */
    var args = {url: '/component/footer-location-branches', async: true}
    if(!isJsonString(localStorage.locationsall) || localStorage.header_content==null || localStorage.footer_link == null ) {
        $.ajaxRequest(args).then(HTML => {
            localStorage.footer_link = HTML.html;
            $('.social-media .footer-links').prepend(HTML.html)
        })
    } else {
        $('.social-media .footer-links').prepend(localStorage.footer_link)
    }

    $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
        if (!$(this).next().hasClass('show')) {
          $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        var $subMenu = $(this).next(".dropdown-menu");
        $subMenu.toggleClass('show');
      
      
        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
          $('.dropdown-submenu .show').removeClass("show");
        });
      
      
        return false;
      });
});

/** fullscreen menu in responsive */
function fullScreenMenu() {
    var burgerBtn  = $('.burgerBtn')
    var appContent = $('.app-content')
    var header     = $('header')
    var navigationOptions = $('#navigation-list .nav-link')

    // add class effect for options of navigation-list
    for (let i = 0; i <= navigationOptions.length; i++) {
        $(navigationOptions).eq(i).addClass('navlink-animation-' + i)
    }

    burgerBtn.on('click', function() {
        appContent.toggleClass('sidenavbar-active');
        header.toggleClass('sidenavbar-active');
    })
}

/** Equivalent to PHP function number_format in jquery/javascript */
function number_format (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

function chunk(array, size) {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i++) {
      const last = chunked_arr[chunked_arr.length - 1];
      if (!last || last.length === size) {
        chunked_arr.push([array[i]]);
      } else {
        last.push(array[i]);
      }
    }
    return chunked_arr;
}

/** AjaxHelpers */
$.ajaxRequest = function (args) {
    return new Promise((resolve, reject) => {
        $.ajax(args).done(resolve).fail(reject)
    })
}
